/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import React, {useEffect, useState} from 'react'
import {
    API,
    ClientDto,
    getDaysOrTimeSinceMsg,
    getDaysSince,
    LAST_SEEN_PROBLEM_DAYS,
    NamedModel,
    ReleaseTypeWithVersion,
    VersionDto,
} from '@/constants/api'

import {handleErrorAsToast} from '@/helpers/errors'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {Card, CardBody} from '@/components/Card/Card'
import PageTitle from '@/components/PageTitle'
import {PAGES} from '@/constants/pages'
import {MDInput} from '@/components/Forms'
import Button from '@/components/Button'
import {toast} from 'react-toastify'
import {
    ArrowDownTrayIcon,
    ArrowPathRoundedSquareIcon,
    ClipboardDocumentCheckIcon,
    TrashIcon,
} from '@heroicons/react/20/solid'
import {DatePicker, Input, Loader, Toggle} from 'rsuite'
import {MDSelect} from '@/components/Select/MDSelect'
import {DynamicModal} from '@/components/Modal/DynamicModal'
import {copyTextToClipboard, getServerOrigin} from '@/helpers/common'
import IconTooltip from '@/components/IconTooltip/IconTooltip'

export const NEW_CLIENT_URL_PARAM = 'new'

function ClientDetails() {
    const {id: clientIdUrlParam} = useParams()

    const navigate = useNavigate()
    const location = useLocation()

    const [clientDetails, setClientDetails] = useState<ClientDto>({
        name: '',
        customer: {id: null, name: ''},
        application: {id: null, name: ''},
        releaseType: null,
        // Override blank fields with any from location state if new client.
        ...(clientIdUrlParam === NEW_CLIENT_URL_PARAM ? {} : location.state?.clientDetails),
        id: null,
        enabled: true,
        autoUpdate: false,
        currentVersion: '',
        availableVersion: '',
        updateAfter: null,
        lastSeen: null,
    })
    const [customers, setCustomers] = useState<NamedModel[]>([])
    const [applications, setApplications] = useState<NamedModel[]>([])
    const [releaseTypes, setReleaseTypes] = useState<ReleaseTypeWithVersion[]>([])
    const [disableButtons, setDisableButtons] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isResettingAuthToken, setResettingAuthToken] = useState(false)
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
    const [showResetAuthConfirmationModal, setShowResetAuthConfirmationModal] = useState(false)
    const [showTokenSecretModal, setShowTokenSecretModal] = useState(false)
    const [secretToken, setSecretToken] = useState('')
    const [availableVersion, setAvailableVersion] = useState<VersionDto>()

    useEffect(() => {
        API.APP.CUSTOMERS.list().then(setCustomers).catch(handleErrorAsToast)
        API.APP.TYPES.APPLICATIONS.list().then(setApplications).catch(handleErrorAsToast)
        if (clientIdUrlParam && clientIdUrlParam !== NEW_CLIENT_URL_PARAM) {
            API.APP.CLIENTS.DETAILS.get(clientIdUrlParam)
                .then((clientDetails) => {
                    setClientDetails(clientDetails)
                    return API.APP.TYPES.RELEASE_TYPES.list(clientDetails.application.id!!)
                })
                .then(setReleaseTypes)
                .catch(handleErrorAsToast)
            // TODO: Check user permissions
        }
    }, [clientIdUrlParam])

    useEffect(() => {
        if (clientDetails.application?.id && clientDetails.releaseType?.id) {
            API.APP.CLIENTS.AVAILABLE_VERSION.get(clientDetails.application.id, clientDetails.releaseType?.id)
                .then((response) => {
                    setAvailableVersion(response)
                })
                .catch(handleErrorAsToast)
        }
    }, [clientDetails.releaseType, clientDetails.application])

    const validateClientDetails = () => {
        if (!clientDetails?.releaseType) {
            toast.error('Please select a release type first')
            return false
        }

        if (!clientDetails?.application) {
            toast.error('Please enter a valid application and then release type first')
            return false
        }

        if (clientDetails?.name?.length <= 2) {
            toast.error('Please enter a valid client name with 3 or more chars')
            return false
        }
        return true
    }

    const saveButtons = (value: boolean) => {
        setIsSaving(value)
        setDisableButtons(value)
    }

    const isLastSeenDanger = (): boolean => {
        const differenceInDays = getDaysSince(clientDetails?.lastSeen)
        return differenceInDays > LAST_SEEN_PROBLEM_DAYS
    }

    const deleteButtons = (value: boolean) => {
        setIsDeleting(value)
        setDisableButtons(value)
    }

    const resetAuthButtons = (value: boolean) => {
        setResettingAuthToken(value)
        setDisableButtons(value)
    }

    const hideModal = () => {
        setShowDeleteConfirmationModal(false)
        setShowResetAuthConfirmationModal(false)
        setShowTokenSecretModal(false)
    }

    const confirmDelete = () => {
        if (!clientDetails?.id) {
            toast.error('Cannot delete a client that has no id')
            return false
        }
        hideModal()
        deleteButtons(true)
        API.APP.CLIENTS.DETAILS.delete(clientDetails.id)
            .then(() => {
                navigate(PAGES.CLIENTS.path)
                toast.success(`Client ${clientDetails.name} deleted`)
            })
            .catch(handleErrorAsToast)
            .finally(() => {
                deleteButtons(false)
            })
    }

    const confirmTokenReset = () => {
        if (!clientDetails?.id) {
            toast.error('Cannot reset auth token on a client that has no id')
            return false
        }
        hideModal()
        resetAuthButtons(true)
        API.APP.CLIENTS.TOKENS.reset(clientDetails.id)
            .then((secret) => {
                setSecretToken(secret)
                setShowTokenSecretModal(true)
            })
            .catch(handleErrorAsToast)
            .finally(() => {
                resetAuthButtons(false)
            })
    }

    const handleSave = () => {
        saveButtons(true)

        if (!validateClientDetails()) {
            saveButtons(false)
            return
        }

        API.APP.CLIENTS.DETAILS.save(clientDetails)
            .then((response) => {
                if (clientDetails.id == null) {
                    navigate(PAGES.CLIENTS.DETAILS.pathTo(response.id!!))
                    toast.success('Client created')
                } else {
                    setClientDetails(response)
                    toast.success('Saved')
                }
            })
            .catch(handleErrorAsToast)
            .finally(() => {
                saveButtons(false)
            })
    }

    const setSelectedCustomer = (selectedCustomer: NamedModel) => {
        setClientDetails((prevClientDetails) => ({...prevClientDetails, customer: selectedCustomer}))
    }
    const setSelectedApplication = (selectedApplication: NamedModel) => {
        setClientDetails((prevClientDetails) => ({
            ...prevClientDetails,
            application: selectedApplication,
            releaseType: null,
        }))
        setReleaseTypes([])
        API.APP.TYPES.RELEASE_TYPES.list(selectedApplication.id!!).then(setReleaseTypes).catch(handleErrorAsToast)
    }
    const setSelectedReleaseType = (selectedReleaseType: NamedModel) => {
        setClientDetails((prevClientDetails) => ({...prevClientDetails, releaseType: selectedReleaseType}))
    }
    const setCustomerName = (name: string) => {
        setClientDetails((prevClientDetails) => ({...prevClientDetails, name: name}))
    }

    const setActiveCustomer = (isActive: boolean) => {
        setClientDetails((prevClientDetails) => ({...prevClientDetails, enabled: isActive}))
    }

    const setAutoUpdate = (isAutoUpdate: boolean) => {
        setClientDetails((prevClientDetails) => ({...prevClientDetails, autoUpdate: isAutoUpdate}))
    }

    const setUpdateAfter = (inputDate: Date | null) => {
        const newDate = inputDate
        // Ignore seconds and milliseconds
        newDate?.setSeconds(0, 0)
        setClientDetails((prevClientDetails) => ({...prevClientDetails, updateAfter: newDate}))
    }

    const deployVersionApiUrl = !clientDetails.id
        ? ''
        : getServerOrigin() +
          API.API.APPLICATIONS.APPLICATION.UPLOAD_VERSION.getPath(clientDetails.application.id!!) +
          '?' +
          new URLSearchParams({releaseType: clientDetails.releaseType?.name ?? '1'})

    const editOrCreate: string = clientIdUrlParam ? `Edit: ${clientDetails?.name}` : 'Create a new client'

    return (
        <React.Fragment>
            <div className='flex items-end space-x-4'>
                <PageTitle page={PAGES.CLIENTS.DETAILS} title={editOrCreate} className='flex-1' />
            </div>
            <div className='flex flex-row gap-4'>
                <Card className='max-w-3xl flex-1'>
                    <CardBody className=' max-w-3xl'>
                        <div className='flex flex-col gap-4'>
                            <div className='grid grid-cols-2 items-center'>
                                Name / Hostname
                                <MDInput
                                    value={clientDetails?.name}
                                    onChange={(clientName) => {
                                        if (clientName) {
                                            setCustomerName(clientName)
                                        }
                                    }}
                                />
                            </div>
                            <div className='grid grid-cols-2 items-center'>
                                Customer
                                <MDSelect
                                    options={customers}
                                    value={clientDetails?.customer}
                                    getOptionLabel={(option: NamedModel) => option.name}
                                    getOptionValue={(option: NamedModel) => (option.id ?? '').toString()}
                                    onChange={(selectedCustomer) => {
                                        if (selectedCustomer) {
                                            setSelectedCustomer(selectedCustomer)
                                        }
                                    }}
                                />
                            </div>
                            <div className='grid grid-cols-2 items-center'>
                                Active
                                <Toggle
                                    checked={clientDetails?.enabled}
                                    onChange={(value) => {
                                        setActiveCustomer(value)
                                    }}
                                />
                            </div>
                            <div className='grid grid-cols-2 items-center'>
                                <div className='flex items-center gap-2'>
                                    <label>Enable Auto Update</label>
                                    <IconTooltip messageKey='Whether the client can update automatically.' />
                                </div>
                                <Toggle
                                    checked={clientDetails?.autoUpdate}
                                    onChange={(value) => {
                                        setAutoUpdate(value)
                                    }}
                                />
                            </div>
                            {clientDetails.id && (
                                <>
                                    <div className='grid grid-cols-2 items-center'>
                                        <div className='flex items-center gap-2'>
                                            <label>Auth Token</label>
                                            <IconTooltip messageKey='Used by the client to authenticate with Squid.' />
                                        </div>
                                        <Button
                                            color='outline-warning'
                                            className='w-24 text-right'
                                            iconRight={
                                                isResettingAuthToken ? <Loader /> : <ArrowPathRoundedSquareIcon />
                                            }
                                            disabled={disableButtons}
                                            onClick={() => {
                                                setShowResetAuthConfirmationModal(true)
                                            }}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </>
                            )}
                            <div className='grid grid-cols-2 items-center'>
                                Application
                                <MDSelect
                                    options={applications}
                                    value={clientDetails?.application}
                                    getOptionLabel={(option: NamedModel) => option.name}
                                    getOptionValue={(option: NamedModel) => (option.id ?? '').toString()}
                                    onChange={(selectedApplication) => {
                                        if (selectedApplication) {
                                            setSelectedApplication(selectedApplication)
                                        }
                                    }}
                                />
                            </div>
                            <div className='grid grid-cols-2 items-center'>
                                Release Type
                                <MDSelect
                                    options={releaseTypes}
                                    value={clientDetails?.releaseType}
                                    getOptionLabel={(option: NamedModel) => `${option.name}`}
                                    getOptionValue={(option: NamedModel) => (option.id ?? '').toString()}
                                    onChange={(selectedReleaseType) => {
                                        if (selectedReleaseType) {
                                            setSelectedReleaseType(selectedReleaseType)
                                        }
                                    }}
                                />
                            </div>
                            {clientDetails.id && (
                                <>
                                    <div className='grid grid-cols-2 items-center'>
                                        Available Version
                                        <div>{availableVersion?.version ?? 'No version available'}</div>
                                    </div>
                                    <div className='grid grid-cols-2 items-center'>
                                        Installed Version
                                        <div>{clientDetails?.currentVersion ?? 'Version not provided yet'}</div>
                                    </div>
                                    <div className='grid grid-cols-2 items-center'>
                                        <div className='flex items-center gap-2'>
                                            <label>External IP</label>
                                            <IconTooltip
                                                messageKey={
                                                    'The `External IP` is populated when the client calls squid APIs.'
                                                }
                                            />
                                        </div>
                                        <div>{clientDetails?.externalIpAddress ?? '-'}</div>
                                    </div>
                                    <div className='grid grid-cols-2 items-center'>
                                        <div className='flex items-center gap-2'>
                                            <label>Internal IP</label>
                                            <IconTooltip
                                                messageKey={
                                                    'The `Internal IP` is populated when the client calls "get version" API with `internalIpAddress` in the request\'s JSON body.'
                                                }
                                            />
                                        </div>
                                        <div>{clientDetails?.internalIpAddress ?? '-'}</div>
                                    </div>
                                    <div className='grid grid-cols-2 items-center'>
                                        <div className='flex items-center gap-2'>
                                            <label>Update After</label>
                                            <IconTooltip messageKey='Schedule a date and time to update. The client can only update its version after this time. If not specified, can update anytime. The current installed version will be returned when update after is in the future.' />
                                        </div>
                                        <DatePicker
                                            format='dd/MM/yyyy HH:mm aa'
                                            value={clientDetails?.updateAfter}
                                            placeholder='Select Date and Time'
                                            onChange={setUpdateAfter}
                                        />
                                    </div>
                                    <div className='grid grid-cols-2 items-center'>
                                        Last Seen
                                        <div
                                            className={isLastSeenDanger() ? 'text-danger' : ''}
                                            title={`${clientDetails?.lastSeen.toLocaleDateString()} ${clientDetails?.lastSeen.toLocaleTimeString()}`}
                                        >
                                            {getDaysOrTimeSinceMsg(clientDetails?.lastSeen)}
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className='flex justify-between'>
                                <Button className='text-right ' color='outline-secondary' onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                                <div className='flex gap-4'>
                                    {clientDetails.id && (
                                        <Button
                                            className='text-right '
                                            color='outline-danger'
                                            iconRight={isDeleting ? <Loader /> : <TrashIcon />}
                                            disabled={disableButtons}
                                            onClick={() => {
                                                setShowDeleteConfirmationModal(true)
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                    <Button
                                        iconRight={isSaving ? <Loader /> : <ArrowDownTrayIcon />}
                                        className=' text-right'
                                        color='primary'
                                        onClick={handleSave}
                                        disabled={disableButtons}
                                    >
                                        {isSaving ? 'Saving...' : 'Save'}
                                    </Button>
                                </div>
                            </div>
                            <DynamicModal
                                isOpen={showDeleteConfirmationModal}
                                toggle={hideModal}
                                heading={`Deleting Client ${clientDetails.name}`}
                                body={[
                                    <p>
                                        Are you sure you want to delete client <strong>{clientDetails.name}?</strong>
                                    </p>,
                                ]}
                                footer={[
                                    <Button onClick={hideModal} color='outline-secondary'>
                                        Cancel
                                    </Button>,
                                    <Button
                                        onClick={confirmDelete}
                                        iconRight={<TrashIcon />}
                                        color='danger'
                                        className='text-right'
                                    >
                                        Delete
                                    </Button>,
                                ]}
                            />
                            <DynamicModal
                                isOpen={showResetAuthConfirmationModal}
                                toggle={hideModal}
                                size={'md'}
                                heading={'Resetting Client Auth Token'}
                                body={[
                                    <p>This cannot be undone!</p>,
                                    <br />,
                                    <p>A new auth token will be generated and displayed.</p>,
                                    <p>
                                        Are you sure you want to <strong>reset</strong> the auth token for{' '}
                                        <strong>{clientDetails.name}</strong>?
                                    </p>,
                                ]}
                                footer={[
                                    <Button onClick={hideModal} color='outline-secondary'>
                                        Cancel
                                    </Button>,
                                    <Button
                                        onClick={confirmTokenReset}
                                        iconRight={<ArrowPathRoundedSquareIcon />}
                                        color='warning'
                                        className='text-right'
                                    >
                                        Reset
                                    </Button>,
                                ]}
                            />
                            <DynamicModal
                                isOpen={showTokenSecretModal}
                                toggle={hideModal}
                                closeOnClickOutside={false}
                                heading={'New Auth Token'}
                                body={[
                                    <p>Copy this token and keep it safe!</p>,
                                    <br />,
                                    <p>
                                        <strong>It wont be available again</strong>
                                    </p>,
                                    <br />,
                                    <p>
                                        <Input size={'lg'} value={secretToken} />
                                    </p>,
                                ]}
                                footer={[
                                    <Button onClick={hideModal} color='outline-primary'>
                                        Close
                                    </Button>,
                                    <Button
                                        onClick={() => {
                                            copyTextToClipboard(secretToken)
                                                .then(() => {
                                                    toast.success('Copied to clipboard!')
                                                })
                                                .catch((err) => {
                                                    toast.error('Failed to copy text!')
                                                })
                                        }}
                                        iconRight={<ClipboardDocumentCheckIcon />}
                                        color='primary'
                                    >
                                        Copy to clipboard
                                    </Button>,
                                ]}
                            />
                        </div>
                    </CardBody>
                </Card>

                {clientDetails.id && (
                    <Card className='max-h-48 max-w-3xl flex-1'>
                        <CardBody className=' max-w-3xl'>
                            <div className='grid grid-rows-2 items-center'>
                                Upload new version API URL
                                <div className='flex flex-nowrap'>
                                    <Input
                                        size={'lg'}
                                        className='rounded-r-none border-r-0'
                                        value={deployVersionApiUrl}
                                    />
                                    <Button
                                        className='btn btn-primary rounded-l-none'
                                        iconRight={<ClipboardDocumentCheckIcon />}
                                        onClick={() => {
                                            copyTextToClipboard(deployVersionApiUrl)
                                                .then(() => {
                                                    toast.success('Copied to clipboard!')
                                                })
                                                .catch((err) => {
                                                    toast.error('Failed to copy text!')
                                                })
                                        }}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                )}
            </div>
        </React.Fragment>
    )
}

export default ClientDetails
