/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2025] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {Tooltip, Whisper} from 'rsuite'
import {InformationCircleIcon} from '@heroicons/react/20/solid'
import classNames from 'classnames'

interface IconTooltipProps {
    messageKey: string
    iconClassName?: string
}

/**
 * Renders an icon with a stylised tooltip.
 */
function IconTooltip({messageKey, iconClassName}: IconTooltipProps) {
    return (
        <Whisper
            placement='bottom'
            speaker={
                <Tooltip className='bg-secondary font-semibold'>
                    <h4>{messageKey}</h4>
                </Tooltip>
            }
        >
            <InformationCircleIcon className={classNames(iconClassName, 'h-5 w-5')} />
        </Whisper>
    )
}

export default IconTooltip
